<template>
  <div class="two-column">
    <div id="center-column" @scroll="onScroll">
      <!-- <div v-if="tabs" id="tabs">
        <div
          v-for="(tab, index) in tabs"
          v-bind:key="index"
          :class="{ tab: true, selected: index == tabIndex }"
          :style="getTabStyle(index == tabIndex)"
          @click="setIndex(index)">
          {{ tab.title }}
        </div>
      </div>
      <div id="item-header" :style="getHeaderStyles()">
        <iframe
          v-if="headerInfo.videoURL"
          width="490"
          height="261"
          :src="`https://www.youtube.com/embed/${headerInfo.videoURL}`"
          title="YouTube video player"
          frameborder="0"
          allow="mute; autoplay; allowfullscreen"></iframe>
        <div
          v-if="headerInfo.images && headerInfo.images.length > 0"
          id="listBuilderCarousel"
          class="carousel slide"
          data-bs-ride="carousel"
          :data-bs-interval="headerInfo.delaySeconds * 1000 || 5000">
          <div class="carousel-inner">
            <div class="carousel-item" v-for="(src, index) in this.headerInfo.images" :class="{ active: index === 0 }" :key="index">
              <img :src="src.imageURL" class="d-block w-100" :alt="'Slide ' + (index + 1)" />
              <div class="carousel-caption d-none d-md-block">
                <p>{{ src.caption }}</p>
              </div>
            </div>
          </div>
          <ol class="carousel-indicators" style="list-style-type: none">
            <li
              v-for="(src, index) in headerInfo.images"
              :key="index"
              data-bs-target="#listBuilderCarousel"
              :data-bs-slide-to="index"
              :class="{ active: index === 0 }" />
          </ol>
          <button
            v-if="headerInfo.images && headerInfo.images.length > 1"
            id="carousel-control-prev"
            class="carousel-control-prev"
            type="button"
            data-bs-target="#listBuilderCarousel"
            data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button
            v-if="headerInfo.images && headerInfo.images.length > 1"
            id="carousel-control-next"
            class="carousel-control-next"
            type="button"
            data-bs-target="#listBuilderCarousel"
            data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
        <h1 v-show="menuVisibility">{{ menuTitle }}</h1>
      </div> -->

      <ul
        :id="displayType == 'categorygrid' ? 'grid-container' : 'items'"
        :class="{ 'hide-actions': action }"
        v-for="(element, index) in chunkKeys"
        :key="index">
        <li :class="displayType == 'categorygrid' ? '' : 'item'">
          <component
            :is="centerComponent"
            :item="chunkList[index]"
            :prevItemId="chunkKeys[index - 1]"
            :locations="locations"
            :listIcons="listIcons"
            :itemId="element"
            :dbContext="dbContext"
            :displayType="displayType"
            @deleteItem="deleteItem"
            @action="setAction"
            @editItem="editItem"
            @updateItem="updateItem"
            :selectedItem="getSelectedItem"
            :inProcess="view == 'in-process'" />
        </li>
      </ul>
    </div>
    <div id="right-column" class="p-2">
      <component
        :is="rightColumnComponent"
        v-bind="rightColumnProps"
        @action="setAction"
        :key="rightColumnKey"
        :rightColumnKey="rightColumnKey"
        :action="action"
        :viewArchive="view == 'view-archived'"
        :inProcess="view == 'in-process'"
        @header-info-update="headerInfoUpdate" />
    </div>
  </div>
</template>

<script>
import axios from 'axios';

import InfiniteScrollMixin from '@/components/mixins/InfiniteScrollMixin.js';
import { typeMap, newObjectMap } from '@/json/listBuilderData.json';
import ButtonNavigation from '@/components/ButtonNavigation.vue';

//Entries
import EntryItems from '@/components/listitems/EntryItems.vue';
import FormEntry from '@/components/forms/FormEntry.vue';
import HuntEntry from '@/components/forms/HuntEntry.vue';

export default {
  components: {
    ButtonNavigation,
    EntryItems,
    FormEntry,
    HuntEntry,
  },
  mixins: [InfiniteScrollMixin],
  data() {
    return {
      type: null,
      listIcons: [],
      tabIndex: 0,
      itemId: null,
    };
  },
  async created() {
    this.addActions();

    const route = this.$route.params;
    this.itemId = route.itemId;
    this.type = route.context;
    this.context = this.type;
    this.displayType = typeMap[this.type][this.tabIndex].displayType;
    this.setDatabasePath(this.getUserWrittenLocationContext(), this.context.replace('.entries', '/') + this.itemId);

    // await this.getLocations();
  },
  async mounted() {
    console.log('params', this.$route.params);
    this.chunkSize = 25;
    this.changeView('view-active');

    await this.nextChunk();
    await this.setAction();
  },
  methods: {
    addActions() {
      this.addAction('edit', async () => {
        this.rightColumnProps = {
          ...(await this.getFormProps(this.rightColumnKey)),
          ...{
            entry: this.getItemByKey(this.rightColumnKey),
            entryId: this.rightColumnKey,
          },
        };
        this.rightColumnComponent = this.rightColumnProps.displayType;
      });

      //Views
      this.addAction('view-active', async () => {
        this.changeView('view-active');
        this.setDatabasePath(this.getUserWrittenLocationContext(), this.context.replace('.entries', '/') + this.itemId);

        await this.nextChunk();
      });
      this.addAction('view-archived', async () => {
        this.changeView('view-archived');
        if (this.type.includes('hunts')) this.setDatabasePath(this.getUserWrittenLocationContext(), 'hunt-submissions/' + this.itemId);
        else {
          //this.setDatabasePath(this.getUserWrittenLocationContext(), this.context.replace('.entries', ''));
          //Path to archived entries would go here, but I don't have any paths to archived entries in my database.
        }

        await this.nextChunk();
      });
      this.addAction('in-process', async () => {
        this.changeView('in-process');
        if (this.type.includes('hunts')) this.setDatabasePath(this.getUserWrittenLocationContext(), 'hunt-submissions/' + this.itemId);

        await this.nextChunk();
      });
      this.addAction('submitted', async () => {
        this.changeView('submitted');
        if (this.type.includes('hunts')) this.setDatabasePath(this.getUserWrittenLocationContext(), 'hunt-submissions/' + this.itemId);

        await this.nextChunk();
      });

      //Exports
      this.addAction('export-data', async () => {
        if (this.chunkKeys && this.chunkKeys.length > 0) {
          var arrData = await this.getArrayData(this.chunkKeys, this.chunkList);
          this.csvExport(arrData);
          this.action = null;
        } else {
          this.$message.create({
            title: 'Error',
            body: 'No data to export',
            buttons: [this.$message.button('OK', 'btn-accent', null)],
            classes: 'amc-modal',
          });
        }
      });
      this.addAction('export-photos', async () => {
        await this.exportFiles('photos');
      });
      this.addAction('export-files', async () => {
        await this.exportFiles('files');
      });
    },

    chunkQuery(query) {
      if (this.view == 'view-archived' || this.view == 'submitted') {
        if (this.lastLoadedKey) query = query.orderByChild('completed').startAt(String(this.lastLoadedKey));
        query = query.limitToFirst(this.chunkSize);
      } else {
        if (this.lastLoadedKey) query = query.orderByKey().startAt(String(this.lastLoadedKey));
        query = query.limitToFirst(this.chunkSize);
      }

      return query;
    },
    async onDataPush() {
      if (this.view == 'view-archived') {
        this.chunkList = this.chunkList.filter((item) => item.completed && item.completed > 0);
        this.chunkKeys = this.chunkList.map((item) => item.key);
      }
      if (this.view == 'view-active') {
        this.chunkList = this.chunkList.filter((item) => !item.completed);
        this.chunkKeys = this.chunkList.map((item) => item.key);
      }

      if (this.view == 'submitted') {
        this.chunkList = this.chunkList.filter((item) => item.submitted == true);
        this.chunkKeys = this.chunkList.map((item) => item.key);
      }
      if (this.view == 'in-process') {
        this.chunkList = this.chunkList.filter((item) => item.submitted == false);
        this.chunkKeys = this.chunkList.map((item) => item.key);
      }
    },

    //Entries-specific methods
    getFormProps: async function () {
      const tempDBContext = this.type == 'feedback' ? 'user-feedback' : this.dbContext.substr(0, this.dbContext.indexOf('/')) || this.dbContext;
      const entryTypes = {
        'hunts.entries': 'HuntEntry',
        'custom-forms.entries': 'FormEntry',
        feedback: 'FormEntry',
        orders: 'StoreOrder',
      };
      const entryType = entryTypes[this.type];
      let currentForm = null;
      if (this.type == 'hunts.entries') {
        const formSS = await this.getLocationRef(this.campgroundKey).child(tempDBContext).orderByChild('key').equalTo(this.itemId).once('value');
        formSS.forEach((currentFormSS) => {
          currentForm = currentFormSS.val();
        });
      } else if (this.type == 'feedback') {
        currentForm = {
          formSchema: [
            {
              label: 'Name',
              name: 'name',
              type: 'text',
            },
            {
              label: 'Site Number',
              name: 'siteNumber',
              type: 'label',
            },
            {
              label: 'Check-In Date',
              name: 'startDate',
              type: 'date',
            },
            {
              label: 'Check-Out Date',
              name: 'endDate',
              type: 'date',
            },
            {
              label: 'Cleanliness',
              name: 'cleanliness',
              type: 'starRating',
            },
            {
              label: 'Staff',
              name: 'staff',
              type: 'starRating',
            },
            {
              label: 'Amenities',
              name: 'amenities',
              type: 'starRating',
            },
            {
              label: 'Additional Comments',
              name: 'additionalComments',
              type: 'textarea',
            },
          ],
        };
      } else if (this.itemId) {
        const formSS = await this.getLocationRef(this.campgroundKey).child(tempDBContext).child(this.itemId).once('value');
        currentForm = formSS.val();
      }
      return {
        section: 'actions',
        context: this.context,
        dbContext: tempDBContext,
        displayType: entryType,
        item: currentForm,
      };
    },
    getArrayData: async function (itemKeys, itemsList) {
      let arrayData = [];
      let labels = [];

      const userEntryData = await this.getFormProps();

      let types = [];
      let ids = [];
      if (userEntryData.displayType == `FormEntry`) {
        ids = userEntryData.item.formSchema
          .filter((formItem) => !['header', 'label', 'static'].includes(formItem.type))
          .map((formItem) => {
            labels.push(formItem.label);
            types.push(formItem.type);
            return formItem.name;
          });
      } else if (userEntryData.displayType == `HuntEntry`) {
        console.log('userEntryData', userEntryData);
        ids = userEntryData.item.steps.map((huntItem) => {
          labels.push(huntItem.title);
          types.push(huntItem.type);
          return huntItem.key;
        });
        ids.push('Total Answers', 'Total Correct Answers', 'Total Incorrect Answers');
        labels = [...labels, ...['Total Answers', 'Total Correct', 'Total Incorrect']];
        types = [...types, ...['text', 'text', 'text']];
      } else {
        ids = ['name', 'siteNumber', 'startDate', 'endDate', 'staff', 'cleanliness', 'amenities', 'additionalComments'];
        labels = ['Name', 'Site', 'Start date', 'End Date', 'Staff', 'Cleanliness', 'Amenities', 'Additional Comments'];
        types = ['text', 'text', 'date', 'date', 'text', 'text', 'text', 'text'];
      }

      arrayData = itemKeys.map((itemKey) => {
        const item = itemsList[itemKeys.indexOf(itemKey)];
        const summary = this.dbContext === 'hunts/entries' ? this.composeSummary(userEntryData.item, item) : [];
        return [
          item.loggedInUser || 'User Not Loaded Yet',
          item.loggedInEmail || 'Please Try Again',
          ...(this.dbContext !== 'hunts/entries'
            ? [item.communityId, item.createDate ? `${this.getFormattedDate(item.createDate)} ${this.getFormattedTime(item.createDate)}` : 'N/A']
            : []),
          ...ids.map((id, index) => {
            let value = '';
            if (this.dbContext === 'hunts/entries') {
              const formItem = item[id] || {};
              // value will be answer or completed.
              if (formItem['drawable']) {
                value = formItem['drawable'];
              } else if (formItem['answer']) {
                value = formItem['answer'];
              } else {
                value = formItem['completed'] ? 'Completed' : 'Not completed';
              }

              if (id in summary) {
                value = summary[id] || '0';
              }
            } else {
              const formItem = item[id];
              value = formItem && formItem.value ? formItem.value : formItem && typeof formItem !== 'object' ? formItem : '';
              if (types[index] == 'date' && value) {
                value = this.getFormattedDate(value);
              } else if (types[index] == 'signature' && !value) {
                value = 'Signed image';
              }
            }
            return value || '';
          }),
        ];
      });

      if (this.displayType === 'hunts') {
        arrayData.unshift(['Name', 'Email Address', ...labels]);
      } else {
        arrayData.unshift(['Logged In User', 'Logged In Email', 'Community Id', 'Date Submitted', ...labels]);
      }

      return arrayData;
    },
    async exportFiles(title) {
      let exportModal;
      try {
        exportModal = this.$message.create({
          title: 'Exporting',
          body: `<div class="row align-items-center" style="padding-left: 24px">
            <div class="col-auto px-0">
              <div class="spinner-border" role="status"></div>
            </div>
            <div class="col">
              <p class="my-4">Exporting ${title}... Please wait</p>
            </div>
          </div>`,
          buttons: [this.$message.button('Cancel', 'btn-accent', null)],
          classes: 'amc-modal',
        });

        let url = `${this.$apihostname}/api/downloads`;
        let storagePath = `user-written/${this.campgroundKey}/hunts/${this.itemId}`;
        if (this.action === 'export-files') {
          //storagePath = `${this.campgroundKey}/forms/completed/${this.itemId}`;
          storagePath = `user-written/${this.campgroundKey}/forms/${this.itemId}`;
          console.log('Export-files path:', storagePath);
        }

        this.action = null;
        const downloadResponse = await axios.post(url, {
          // reading from android code where the images are stored, they are stored in this location.
          storagePath,
        });
        console.log('🚀 ~ file: ListBuilder.vue:1028 ~ setAction:function ~ downloadResponse', downloadResponse);
        const downloadResponseData = downloadResponse.data;
        if (downloadResponseData.err) {
          setTimeout(() => {
            this.$message.hide(exportModal);

            this.$message.create({
              title: 'Error',
              body: downloadResponseData.message,
              buttons: [this.$message.button('OK', 'btn-accent', null)],
              classes: 'amc-modal',
            });
          }, 500);
        } else {
          setTimeout(() => {
            this.$message.hide(exportModal);

            this.downloadUrl = downloadResponseData.downloadUrl;
            this.$message.create({
              title: 'Download',
              body: 'Data was exported successfully. Click Download button to start downloading zip file',
              buttons: [
                this.$message.button('Dismiss', 'btn-secondary', null),
                this.$message.button('Download', 'btn-accent', () => {
                  console.log(`Opening download link: ${this.$hostname}${this.downloadUrl}`);
                  window.open(`${this.$hostname}${this.downloadUrl}`, '_blank', 'noreferrer');
                }),
              ],
              classes: 'amc-modal',
            });
          }, 500);
        }
      } catch (e) {
        console.error(e);
        setTimeout(() => this.$message.hide(exportModal), 1000);
      }
    },
  },
};
</script>
