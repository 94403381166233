<template>
  <div>
    <div class="mb-3">
      <label class="mb-2" for="date">{{ label }}</label>
      <div class="input-group mb-1">
        <VueDatePicker
          ref="datePicker"
          v-model="date"
          model-type="timestamp"
          placeholder="Select Date"
          format="MM/dd/yyyy"
          :min-date="minDate ? new Date(minDate) : null"
          :max-date="maxDate ? new Date(maxDate) : null"
          week-start="0"
          :enable-time-picker="false"
          :is-24="false"
          :disabled="disabled"
          :teleport="true"
          :clearable="clearable"
          :state="isInvalid ? false : null"
          @cleared="
            () => {
              date = null;
              emit();
            }
          "
          @date-update="
            (date) => {
              $nextTick(() => {
                this.$refs.datePicker.selectDate();
                this.emit();
                if (timeRequested) this.$refs.timePicker.openMenu();
              });
            }
          " />
        <VueDatePicker
          ref="timePicker"
          v-if="timeRequested"
          v-model="time"
          placeholder="Select Time"
          format="hh:mm aa"
          :is-24="false"
          time-picker
          :disabled="disabled"
          :teleport="true"
          :clearable="clearable"
          :start-time="internalStartTime"
          :state="isInvalid ? false : null"
          @cleared="
            () => {
              time = null;
              emit();
            }
          "
          @update:model-value="emit()" />
        <slot name="validation">
          <Validation classes="mb-3" :validator="isInvalid" :feedback="errorMessage" />
        </slot>
      </div>
      <div class="form-text">{{ description }}</div>
    </div>
  </div>
</template>

<script>
import moment from 'moment-timezone';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

export default {
  components: { VueDatePicker },
  props: {
    label: { type: String, default: 'Date' },
    description: { type: String, default: '' },
    value: { type: Number, required: true },
    minDate: { type: Number, default: Date.now() },
    maxDate: { type: Number, default: null },
    startTime: { type: Object, default: null },
    nullDefault: { default: 0 },
    //Validation
    submitted: { type: Boolean, default: false },
    isInvalid: { type: Boolean, default: false },
    errorMessage: { type: String, default: 'Date and time are required' },
    //Options
    disabled: { type: Boolean, default: false },
    clearable: { type: Boolean, default: true },
    roundHour: { type: Boolean, default: true },
    timeRequested: { type: Boolean, default: true },
  },
  data() {
    return {
      internalStartTime: { hours: 0, minutes: 0 },
      date: null,
      time: null,
      timezone: window.localStorage.getItem('CAMPGROUND_TIMEZONE'),
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        if (newValue) this.set(newValue);
      },
    },
    date: {
      immediate: true,
      handler(newValue) {
        if (newValue) this.$emit('date-state', true);
        else this.$emit('date-state', false);
      },
    },
    time: {
      immediate: true,
      handler(newValue) {
        if (newValue) this.$emit('time-state', true);
        else this.$emit('time-state', false);
      },
    },
  },
  mounted() {
    if (this.roundHour) {
      const now = moment.tz(this.timezone);
      this.internalStartTime = { hours: now.hours() + 1, minutes: 0 };
    }
  },
  methods: {
    emit() {
      if (!this.timeRequested) {
        this.time = { hours: 0, minutes: 0 };
      }
      if (this.date && this.time) {
        const date = moment.tz(this.date, this.timezone);
        const timestamp = moment
          .tz({ year: date.year(), month: date.month(), day: date.date(), hour: this.time.hours, minute: this.time.minutes }, this.timezone)
          .valueOf();

        if (timestamp) {
          this.$emit('update', timestamp);

          if (!this.submitted) {
            this.vuelidate$.$reset();
          }
        }
      }

      if (!this.date && (!this.time || !this.timeRequested)) this.$emit('update', this.nullDefault);
    },
    set(timestamp) {
      const date = moment.tz(timestamp, this.timezone);
      this.date = date.clone();
      this.time = { hours: date.hours(), minutes: date.minutes() };
    },
    validate() {
      if (this.submitted == true) {
        this.vuelidate$.$touch();
        this.$emit('invalid', this.vuelidate$.$error);
      }
    },
    clear() {
      this.$refs.datePicker.clearValue();
      this.$refs.timePicker.clearValue();
    },
  },
};
</script>
