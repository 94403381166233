<!-- eslint-disable vue/order-in-components -->
<!-- eslint-disable vue/attribute-hyphenation -->
<template>
  <div :style="getBackground">
    <FullCalendar v-if="colors.background" ref="fullCalendar" :options="calendarOptions" :duration="{ days: duration }" :views="views" />
    <b-modal
      id="event-modal"
      ref="modal"
      :title="modalTitle"
      size="lg"
      classes="amc-modal"
      :backgroundColor="colors.background"
      :textColor="colors.text"
      :buttons="[{ title: 'Dismiss', variant: 'btn-secondary' }]">
      <template #body>
        <div v-html="modalHTML" />
      </template>
    </b-modal>
  </div>
</template>

<script>
import moment from 'moment';
import FullCalendar from '@fullcalendar/vue3';
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
import BModal from '../components/common/BModal.vue';
import { getDatabase, ref, onValue } from 'firebase/database';

export default {
  name: 'Calendar',
  components: {
    FullCalendar,
    BModal,
  },
  data() {
    return {
      calendarOptions: {
        initialView: 'listMonth',
        plugins: [dayGridPlugin, listPlugin],
        events: [],
        nextDayThreshold: '04:00:00',
        eventClick: this.eventClick,
        eventDidMount: this.eventRender,
        editable: false,
        droppable: false,
        eventStartEditable: 'false',
        headerToolbar: { left: 'none', right: 'none', center: 'title' },
      },
      colors: {
        accent: '',
        background: '',
        primary: '',
        text: '#ffffff',
        date: '#0d6efd',
      },
      dbRef: null,
      clocation: '',
      dbContext: 'events-resort',
      isTabbed: '',
      startDate: '',
      endDate: '',
      duration: '',
      defaultHeight: '',
      timeZone: '',
      locations: {},
      views: {},
      modalHTML: null,
      modalTitle: null,
      isCondensed: false,
    };
  },
  computed: {
    getBackground: function () {
      return { 'background-color': this.colors.background, 'border-color': this.colors.background };
    },
  },
  async created() {
    this.views = {
      list: {
        validRange: {
          start: moment().format(),
        },
      },
    };
    this.clocation = this.$route.query.clocation || 'demo-campground';
    this.dbContext = this.$route.query.selectedTab || 'events-resort';
    this.calendarOptions.initialView = this.$route.query.defaultView || this.calendarOptions.initialView;
    this.isCondensed = this.$route.query.isCondensed === 'true' && this.calendarOptions.initialView === 'listMonth';
    this.isTabbed = this.$route.query.isTabbed;
    this.calendarOptions.defaultDate = this.$route.query.startDate || null;
    this.endDate = this.$route.query.endDate || null;
    this.calendarOptions.duration = this.$route.query.duration || null;
    this.calendarOptions.height = parseInt(this.$route.query.defaultHeight) - 16 || null;
    this.dbRef = this.getLocationRef(this.clocation);
    this.dbRef
      .child('locations')
      .once('value')
      .then((snapshotLocations) => {
        this.locations = snapshotLocations.val();
      });
    const locationData = await this.getCampgroundLocationData(this.clocation);
    Object.keys(this.colors).forEach((key) => {
      const paramVar = `color${key.charAt(0).toUpperCase() + key.slice(1)}`;
      this.colors[key] = this.$route.query[paramVar] ? this.$route.query[paramVar] : locationData[paramVar] || this.colors[key];
      this.colors[key] = this.colors[key].startsWith('#') ? this.colors[key] : `#${this.colors[key]}`;
    });
    document.body.style['overflow-y'] = 'hidden';
    this.calendarOptions.headerToolbar =
      this.$route.query.hideArrows == 'true' ? { left: 'none', right: 'none', center: 'title' } : { left: 'prev', right: 'next', center: 'title' };
    this.timeZone = locationData.timeZone;
    const legacyEvents = ['events-resort', 'events-local'].includes(this.dbContext);
    const fbPath = legacyEvents ? this.dbContext.replace('.', '/') : `amc-events/calendars/${this.dbContext.replace('.', '/')}/events`;
    const eventsRef = ref(getDatabase(this.$firebase), `resort-navigator/location-data/${this.clocation}/${fbPath}`);
    console.log('TCL: created -> eventsRef', eventsRef.toString());
    onValue(eventsRef, (eventSS) => {
      this.calendarOptions.events = [];
      eventSS.forEach((element) => {
        const key = element.key;
        let elementData = element.val();
        const startDate = legacyEvents ? parseInt(key) : elementData.startDate;
        const location = elementData.resortLocationId ? elementData.resortLocationId : elementData.location;
        elementData = {
          ...elementData,
          ...{
            id: key,
            start: moment.tz(startDate, this.timeZone).format(),
            end: moment.tz(startDate + elementData.endDateDuration, this.timeZone).format(),
            locationName: this.locations[location] && this.locations[location].title ? this.locations[location].title : location,
          },
        };
        this.calendarOptions.events.push(elementData);
      });
      console.log(this.calendarOptions);
    });
  },
  methods: {
    getDescription: function (event) {
      const startDate = moment.tz(event.start, this.timeZone).format('MMMM Do YYYY @ h:mm a');
      const endDate = moment.tz(event.end, this.timeZone).format('MMMM Do YYYY @ h:mm a');
      let html = `<div class='description-container ${this.isCondensed ? 'is-condensed' : ''}'><div class='image-container'>`;
      html += event.extendedProps.drawable ? `<img class="event-image" src="${event.extendedProps.drawable}"/>` : '';
      html += '</div><div class="event-content">';
      if (!this.isCondensed) {
        html += `<span class="fw-bold pr-1">Start Time:</span><span class="event-value">${startDate}</span><br/>`;
        if (!event.allDay) html += `<span class="fw-bold pr-1">End Time:</span><span class="event-value">${endDate}</span><br/>`;
      }
      html += `<span class="fw-bold pr-1">Location:</span><span class="event-value">${event.extendedProps.locationName}</span><br/>`;
      html += `${event.extendedProps.description}</div></div>`;
      return html;
    },
    eventRender: function (info) {
      console.log('🚀 ~ file: Calendar.vue ~ line 179 ~ eventRender', info);
      if (info.view.type.startsWith('list')) {
        const title = info.el.querySelectorAll('.fc-list-event-title a')[0];
        title.innerText = `${title.innerText} @${info.event.extendedProps.locationName}`;
        if (this.$route.query.expand === 'true') {
          this.displayDescription(info);
        }
      }
    },
    displayDescription: function (info) {
      const tableEl = info.el.parentNode.parentNode;
      if (tableEl.rows[info.el.rowIndex + 1] && tableEl.rows[info.el.rowIndex + 1].className == 'event-description') {
        tableEl.deleteRow(info.el.rowIndex + 1);
      } else {
        const tableRow = tableEl.insertRow(info.el.rowIndex + 1);
        tableRow.style.color = this.colors.text;
        // tableRow.style.backgroundColor = this.colors.background;
        tableRow.className = 'event-description';
        const cell1 = tableRow.insertCell(0);
        cell1.colSpan = 3;
        cell1.innerHTML = this.getDescription(info.event);
      }
    },
    eventClick: function (info) {
      if (info.view.type.startsWith('list')) {
        this.displayDescription(info);
      } else {
        this.modalHTML = this.getDescription(info.event);
        this.modalTitle = info.event.title;
        this.$refs.modal.show();
      }
      //this.eventRender(info);
    },
    dismissModal: function () {
      this.$refs.modal.hide();
    },
  },
};
</script>

<style lang="scss">
// @import '~@fullcalendar/core/main.css';
// @import '~@fullcalendar/daygrid/main.css';
// @import '~@fullcalendar/list/main.css';
.event-content span {
  display: inline-block;
}
.event-label {
  font-weight: bold;
}
.event-value {
  padding-left: 5px;
  padding-bottom: 5px;
}
div#dialog-form .event-image {
  display: block;
  margin: auto;
  float: none;
  padding-bottom: 5px;
  max-width: 300px;
}

.fc-list-day-text,
.fc-list-day-side-text,
.fc-list-day-text:hover,
.fc-list-day-side-text:hover {
  text-decoration: none;
  color: #222222;
}

.fc-day-grid-event .fc-content {
  white-space: inherit;
  margin-bottom: 4px;
}
.fc-list-item-marker.fc-widget-content,
.fc-today-button.fc-state-disabled,
.fc-event-dot {
  display: none;
}
.fc-list-event,
.fc-event {
  cursor: pointer;
}
.fc-button {
  color: #ffffff;
  border: none;
  outline: 0;
}
button.fc-none-button.fc-button.fc-button-primary {
  display: none !important;
}
.fc .fc-toolbar.fc-header-toolbar {
  margin: 1em;
}

.fc .fc-list-event:hover {
  filter: brightness(80%);
}
.fc .fc-list-event:hover td {
  background: inherit;
}
.fc .fc-daygrid-day-number,
.fc .fc-col-header-cell-cushion {
  color: v-bind('colors.date');
}
.fc-toolbar-title {
  color: v-bind('colors.text');
}
.fc-scroller {
  background-color: v-bind('colors.background');
}
.fc-list-event {
  background-color: v-bind('colors.primary');
  color: v-bind('colors.text');
}
.fc-button {
  background-color: v-bind('colors.accent') !important;
}
.fc-event {
  color: v-bind('colors.text');
}
.description-container {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  border: 1px solid #ccc;
  padding: 10px;
  max-width: 100%;
  gap: 10px;
}
.description-container .image-container {
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 400px;
  max-height: 250px;
  overflow: hidden; /* Ensure the image does not overflow the container */
  display: flex;
  justify-content: center;
  align-items: center;
}

.description-container.is-condensed .image-container {
  max-height: 125px;
  max-width: 250px;
}

.event-image {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover; /* Scale the image to cover the container */
  object-position: center; /* Center the image within the element */
}
.description-container .event-content {
  flex: 1;
  min-width: 0;
  flex-grow: 1;
}

@media (max-width: 800px) {
  .description-container {
    flex-direction: column;
  }

  .description-container .image-content {
    width: 100%;
  }
  .description-container .event-content {
    width: 100%;
  }
}
</style>
